import React, { useEffect, useRef, useState } from 'react'
import { Portal, PortalHost } from '@gorhom/portal'
import { Avatar } from 'components/common/Avatar'
import { HEADER_HEIGHT } from 'constants/Header'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { environment } from 'src/config'
import { Colors } from 'src/constants/Colors'
import { getWidth } from 'src/utils/getWidth'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { FOOTER_HEIGHT } from 'components/Footer/Footer'
import { useHelp } from 'src/context/help/help'
import { useNavigation } from '@react-navigation/native'
import {
  AUTH_STATE,
  AUTH_WEIGHT,
  authTypeService,
  useAuth,
  userOrganisationService,
  userSessionService,
} from 'src/context/auth'
import { PasswordAction } from 'src/context/auth/utils/type'
import { reloadApp } from 'src/utils/reloadApp'

const { isWeb } = environment

type Props = {
  signOut: () => void
  hideDropdownMenu: () => void
  showVerifyPinDialog: () => void
  username: string
}

const DROPDOWN_MENU_PORTAL_NAME = 'DROPDOWN_MENU_PORTAL'
const WIDTH = 300
const spaceToRight = 16
const spaceToHeader = 16
const spaceToFooter = 16
const nameItemHeight = 55
const itemHeight = 38

export const DropdownMenuPortal: React.FC = () => (
  <PortalHost name={DROPDOWN_MENU_PORTAL_NAME} />
)

const isTargetInAppContainer = (target: EventTarget | null) => {
  // All third-part modal are out side of app-container.
  return document.getElementById('app-container')?.contains(target as Node)
}

export const SoftLogout: React.FC<Props> = ({
  username,
  signOut,
  showVerifyPinDialog,
  hideDropdownMenu,
}) => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const { toggleAuthState } = useAuth()

  const contentContainerRef = useRef<View>(null)

  useEffect(() => {
    if (!isWeb) {
      return
    }

    const listener = (event: Event) => {
      if (isTargetInAppContainer(event.target)) {
        hideDropdownMenu()
        event.stopPropagation()
      }
    }

    requestAnimationFrame(() =>
      window.addEventListener('click', listener, true),
    )

    return () => window.removeEventListener('click', listener, true)
  }, [hideDropdownMenu])

  const { isLandscape, isPortrait, isLargeScreen } = useBreakpoint()

  const [screenWidth, setScreenWidth] = useState(0)

  const { toggleHelpModal } = useHelp()

  const switchToSelectOrganisation = async () => {
    userOrganisationService.deselect()
    userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
    reloadApp()
  }

  const switchToChangePassword = () => {
    userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
    const newAuthState = toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, false)
    navigation.navigate(newAuthState.toLowerCase(), {
      email: userSessionService.getUserSession()?.userAttributes?.email,
      action: PasswordAction.CHANGE_PASSWORD_IN_SESSION,
    })
  }

  const isAuthV2 = authTypeService.getIsAuthV2()
  const hasTwoOrgs = userSessionService.getUserOrgIds().length > 1

  return (
    <Portal hostName={DROPDOWN_MENU_PORTAL_NAME}>
      {!isWeb && (
        <TouchableOpacity
          testID="soft-logout-backdrop"
          style={styles.backdrop}
          onPress={hideDropdownMenu}
          onLayout={event => {
            setScreenWidth(getWidth(event))
          }}
        />
      )}
      <View
        testID="IconButton-SoftLogout"
        ref={contentContainerRef}
        style={[
          styles.container,
          !isWeb
            ? { left: screenWidth - WIDTH - spaceToRight }
            : { right: spaceToRight },
          !isWeb &&
            (isLandscape || isPortrait
              ? styles.containerIOSLarge
              : styles.containerIOS),
        ]}
      >
        <View style={styles.nameItem}>
          <Avatar name={username} size={24} radius={12} />
          <Text style={styles.nameText}>{username}</Text>
        </View>
        <View style={styles.item}>
          <TouchableOpacity
            testID="soft-logout-lock-button"
            onPress={showVerifyPinDialog}
          >
            <Text>{t('account:general.lock')}</Text>
          </TouchableOpacity>
        </View>
        {isAuthV2 && hasTwoOrgs ? (
          <View style={styles.item}>
            <TouchableOpacity
              testID="soft-logout-switch_organisations"
              onPress={switchToSelectOrganisation}
            >
              <Text>{t('account:general.switchOrganisations')}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
        {isAuthV2 ? (
          <View style={styles.item}>
            <TouchableOpacity
              testID="soft-logout-change-password"
              onPress={switchToChangePassword}
            >
              <Text>{t('account:general.changePassword')}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
        {(!environment.isWeb || !isLargeScreen) && (
          <View style={styles.item}>
            <TouchableOpacity onPress={toggleHelpModal}>
              <Text>{t('account:general.help')}</Text>
            </TouchableOpacity>
          </View>
        )}
        <View style={[styles.item, styles.last]}>
          <TouchableOpacity
            testID="soft-logout-sign-out-button"
            onPress={signOut}
          >
            <Text style={styles.textStyle}>{t('account:general.signOut')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Portal>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    position: 'absolute',
    width: WIDTH,
    top: environment.isWeb ? HEADER_HEIGHT + spaceToHeader : undefined,
    borderWidth: 0,
    borderRadius: 6,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
  },
  containerIOS: {
    bottom: 90,
  },
  containerIOSLarge: {
    bottom: FOOTER_HEIGHT + spaceToFooter,
  },
  nameItem: {
    height: nameItemHeight,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderColor: 'transparent',
    borderWidth: 1,
    borderBottomColor: Colors.borderGrey,
    paddingLeft: 30,
  },
  nameText: {
    fontFamily: Fonts.bold,
    paddingLeft: 8,
  },
  item: {
    height: itemHeight,
    paddingTop: 12,
    marginLeft: 30,
    fontFamily: Fonts.regular,
  },
  last: {
    paddingTop: 6,
  },
  textStyle: {
    color: Colors.cancelRed,
  },
})
