import React from 'react'
import { View, Image, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Heading } from 'src/design-system/components/Text'
import { FontFamilies } from 'src/design-system/theme/typography/base'

type Props = {
  title: string
  hasVRLogo?: boolean
  style?: StyleProp<ViewStyle>
}

export const AuthHeader: React.FC<Props> = ({
  title,
  hasVRLogo = true,
  style,
}) => {
  return (
    <View style={[styles.headContainer, style]}>
      {hasVRLogo ? (
        <Image
          source={require('assets/images/vetradar-icon.png')}
          resizeMode="contain"
          style={styles.logoIcon}
        />
      ) : null}
      <Heading size={'XL'} style={[styles.boldFont]}>
        {title}
      </Heading>
    </View>
  )
}

const styles = StyleSheet.create({
  headContainer: {
    marginTop: 32,
    gap: 8,
  },
  logoIcon: {
    width: 50,
    height: 50,
  },
  widthForSmallish: {
    width: 200,
  },
  boldFont: {
    fontFamily: FontFamilies.bold,
  },
})
