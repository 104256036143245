import { authTypeService, CheckAuthType } from './authTypeService'
import { userOrganisationService } from './userOrganisationService'
import { userPinSessionService, UserPinSession } from './userPinService'
import { userSessionService } from './userSession'
import { AuthAPI } from './api'
import {
  ErrorCode,
  skipAuthMap,
  SkipAuthType,
  UserInfo,
  UserSession,
} from './type'
import { environment } from 'src/config'

export const ONE_MIN = 60000

export class Auth {
  static async checkAuthVersion() {
    const organisationId = userOrganisationService.getOrganisationId()
    const authType = {
      organisationId,
      isAuthV2: false,
    }
    if (!organisationId) return
    const result: CheckAuthType = await AuthAPI.get(
      `/check-auth-version/${organisationId}`,
    )

    if (result?.isAuthV2) {
      authType.isAuthV2 = result.isAuthV2
    }

    return authTypeService.setAuthType(authType)
  }

  static async currentSession(): Promise<null | UserSession> {
    const sessionData = userSessionService.getUserSession()
    if (!sessionData) return null

    const { expiredAt } = sessionData.authenticationResult
    const buffer = ONE_MIN * 1
    const expiredTime = new Date(expiredAt)
    const beforeExpiration = new Date(expiredTime.getTime() - buffer)
    if (new Date() > beforeExpiration) {
      return this.refreshToken()
    }
    return sessionData
  }

  static async getUserInfo() {
    const result = await AuthAPI.get('/user-info')
    if (result?.errorCode) return result
    userSessionService.setUserSession({
      userInfo: result,
    })
  }

  static async setUpPin(pin: string) {
    const session = userSessionService.getUserSession()
    if (!session) return
    const result = await AuthAPI.post('/setup-pin', {
      headers: {
        token: session?.pinInfo?.token,
      },
      payload: {
        pin,
      },
    })
    if (result?.errorCode) return result
    userPinSessionService.setUserPinSession(session, result.devicePinId)
  }

  static async signInWithPin(selectedUser: UserPinSession, pin: string) {
    const { cognitoUserId, pinId, userId } = selectedUser
    const result = await AuthAPI.post('/sign-in-with-pin', {
      payload: {
        userId: cognitoUserId,
        devicePinId: pinId,
        pin,
      },
    })
    if (result?.errorCode) {
      if (result.errorCode === ErrorCode.PIN_MAX_ATTEMPT) {
        userPinSessionService.deleteUserPinSession(userId)
        userSessionService.deleteUserSession()
      }
      return result
    }
    userSessionService.setUserSession(result)
  }

  static async signIn(email: string, password: string): Promise<any> {
    const result = await AuthAPI.post('/sign-in', {
      payload: {
        email,
        password,
      },
    })
    if (result?.errorCode) {
      const offset =
        result.errorCode === ErrorCode.PASSWORD_OR_EMAIL_MAX_ATTEMPT ? 10 : 0
      userSessionService.increaseAuthFailures(offset)
      return result
    }
    if (result?.ChallengeName) return result
    userSessionService.setUserSession(result)
  }

  static async skipAuth(action: SkipAuthType) {
    const result = await AuthAPI.post('/skip-auth-step', {
      payload: {
        action,
      },
    })
    if (result?.errorCode) return result
    const userActionField = skipAuthMap[action]
    if (!result[userActionField]) return
    const session = userSessionService.getUserSession()
    userSessionService.setUserSession({
      userInfo: {
        ...(session?.userAttributes || {}),
        [userActionField]: result[userActionField],
      } as UserInfo,
    })
  }

  static async respondNewPasswordChallenge(
    email: string,
    newPassword: string,
    session: string,
  ) {
    const result = await AuthAPI.post(
      '/respond-new-password-required-challenge',
      {
        payload: {
          userId: email,
          newPassword,
          session,
        },
      },
    )
    if (result?.errorCode) return result
    userSessionService.setUserSession(result)
  }

  static async sendEmailVerification() {
    return AuthAPI.post('/send-email-verification')
  }

  static async verifyEmailVerificationCode(code: string) {
    return AuthAPI.post('/verify-email-verification-code', {
      payload: {
        code,
      },
    })
  }

  // if deleteDevicePin is false, then it's user switch request
  // if deleteDevicePin is true, then it's sign-out request
  static async signOut(deleteDevicePin = true, retries = 0) {
    const session = userSessionService.getUserSession()
    const cognitoUserId = session?.userAttributes?.cognito_user_id
    const userId = session?.userAttributes?.id || ''
    if (!cognitoUserId) return
    const devicePinId = deleteDevicePin
      ? userPinSessionService.getUserPinSession(userId)?.pinId
      : null
    const result = await AuthAPI.post(
      '/sign-out',
      {
        payload: {
          userId: cognitoUserId,
          ...(devicePinId && { devicePinId }),
        },
      },
      retries,
    )
    if (result?.errorCode) return result
    if (devicePinId) {
      userPinSessionService.deleteUserPinSession(userId)
    }
    userSessionService.deleteUserSession()
  }

  static async changePassword(
    previousPassword: string,
    proposedPassword: string,
    userId: string,
  ) {
    return AuthAPI.post('/change-password', {
      payload: {
        previousPassword,
        proposedPassword,
        userId,
      },
    })
  }

  static async confirmForgetPassword(
    email: string,
    code: string,
    password: string,
  ) {
    return AuthAPI.post('/confirm-forgot-password', {
      payload: {
        email,
        code,
        password,
      },
    })
  }

  static async forgetPassword(email: string) {
    return AuthAPI.post('/forgot-password', {
      payload: {
        email,
      },
    })
  }

  static async refreshToken(refreshToken?: string, retries = 2) {
    const session = userSessionService.getUserSession()
    if (!refreshToken && !session?.authenticationResult) return
    const result = await AuthAPI.post(
      '/refresh-token',
      {
        headers: {
          'refresh-token':
            refreshToken ?? session?.authenticationResult.refreshToken,
        },
      },
      retries,
    )
    if (result?.errorCode) {
      await this.signOut()
      if (environment.isWeb) window.history.pushState(null, '', '/login')
      return
    }
    userSessionService.setUserSession({
      authenticationResult: {
        ...session?.authenticationResult,
        ...result,
      },
    })
    return result
  }
}
