import React, { ReactElement } from 'react'
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { Colors } from 'src/design-system/theme'

type Props = {
  icon: ReactElement
  onPress?: () => void
  iconStyle?: any
  onFocus?: () => void
  containerStyle?: StyleProp<ViewStyle>
  disabled?: boolean
}

export const PressableIcon: React.FC<Props> = ({
  icon,
  onPress,
  onFocus,
  iconStyle,
  disabled = false,
  containerStyle = styles.iconContainer,
}) => {
  const iconStyles = {
    color: disabled
      ? Colors.Interactive.disabledSecondary
      : Colors.Contents.accessory,
    ...iconStyle,
  }

  return (
    <Pressable
      onFocus={onFocus}
      style={containerStyle}
      onPress={onPress}
      disabled={disabled}
    >
      {React.cloneElement(icon, iconStyles)}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    height: '100%',
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
