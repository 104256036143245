import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { Button } from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Heading, Label } from 'src/design-system/components/Text'
import { RuleStatus } from 'src/design-system/components/TextInput/TextInput'
import { Colors } from 'src/design-system/theme'
import { InvalidStatus } from '../components/InvalidStatus'
import { Layout } from '../components/Layout'
import { PinInput } from '../components/PinInput'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  ONE_MIN as BUFFER,
  useAuth,
  userPinSessionService,
  userSessionService,
} from 'src/context/auth'
import { SkipAuthType } from 'src/context/auth/utils/type'

export const SetupPin: React.FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const [pin, setPin] = useState<string>('')
  const [errors, setErrors] = useState<string[]>([])
  const { toggleAuthState } = useAuth()

  const handleToggleAuthState = useCallback(() => {
    userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
    toggleAuthState(AUTH_STATE.LOGIN, true)
    toggleAuthState(AUTH_STATE.UNLOCK_WITH_PIN, true)
  }, [toggleAuthState])

  const handleConfirmPin = useCallback(async () => {
    const result = await Auth.setUpPin(pin)
    if (!!result?.errorCode) {
      return
    }

    handleToggleAuthState()
  }, [pin, handleToggleAuthState])

  const handleSkip = useCallback(async () => {
    const result = await Auth.skipAuth(SkipAuthType.SET_UP_PIN)
    if (!!result?.errorCode) {
      return
    }

    handleToggleAuthState()
  }, [handleToggleAuthState])

  const handlePinInput = useCallback((value: string) => {
    // TODO: Verify if more logic is required, but otherwise we can just store into state
    setPin(value)
  }, [])

  const handlePinValidation = (results: RuleStatus[] | null) => {
    setErrors(results?.filter(r => !r.isValid).map(r => r.message) ?? [])
  }

  useLayoutEffect(() => {
    const initPINSwitch = () => {
      const userSession = userSessionService.getUserSession()
      if (!!userSession) {
        const pinInfo = userPinSessionService.hasPin(
          userSession?.userAttributes?.id ?? '',
        )
        const isPinSkipped =
          new Date() <
          new Date(
            userSession?.userAttributes?.skip_setup_pin_exp || Date.now(),
          )
        if (!!pinInfo || isPinSkipped) {
          handleToggleAuthState()
        }
      }
    }
    initPINSwitch()
  }, [handleToggleAuthState])

  useEffect(() => {
    const expiredAt = userSessionService.getUserSession()?.pinInfo?.expiredAt
    let timeout = BUFFER * 14 // default 14 minutes= 15 - 1(Buffer)
    if (expiredAt) {
      timeout = new Date(expiredAt).getTime() - new Date().getTime() - BUFFER
    }
    const timer = setInterval(() => {
      handleToggleAuthState()
      clearInterval(timer)
    }, timeout)

    return () => clearInterval(timer)
  }, [handleToggleAuthState])

  return (
    <Layout>
      <View style={styles.container}>
        <Heading size="XL">Set up PIN</Heading>
        <Label size="L">This will allow you to log in quickly</Label>
        <View style={styles.pinContainer}>
          <PinInput
            onChange={handlePinInput}
            onConfirm={handleConfirmPin}
            onValidation={handlePinValidation}
          />
          {errors
            ? errors.map((e, idx) => <InvalidStatus status={e} key={idx} />)
            : null}
        </View>
        <View style={styles.buttonContainer}>
          <Button
            ariaLabel="ConfirmPinBtn"
            onPress={handleConfirmPin}
            title={t('login:pin:confirm')}
            style={styles.fullWidth}
            containerStyle={styles.noHorizontalPadding}
          />
          <Button
            ariaLabel="SkipPinBtn"
            onPress={handleSkip}
            title={t('login:pin:skip')}
            style={[styles.skipButton, styles.fullWidth]}
            containerStyle={styles.noHorizontalPadding}
            textStyle={{ color: Colors.Contents.primary }}
          />
        </View>
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  fullWidth: {
    width: '100%',
  },
  noHorizontalPadding: {
    paddingHorizontal: 0,
  },
  pinContainer: {
    maxWidth: 576,
    marginHorizontal: 'auto',
    marginVertical: 32,
    width: '100%',
    rowGap: 8,
  },
  buttonContainer: {
    gap: 8,
    maxWidth: 576,
    width: '100%',
  },
  skipButton: {
    borderWidth: 2,
    borderColor: Colors.Borders.tertiary,
    backgroundColor: Colors.Backgrounds.UI,
  },
})
