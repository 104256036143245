import { ActionSheetOptions } from '@expo/react-native-action-sheet'
import { useCallback } from 'react'
import { isNil } from 'lodash'

export type ButtonSettings = {
  action: () => void
  destructive?: boolean
  name: string
}

type ShowActionSheetHook = (
  options: ActionSheetOptions,
  callback: (i?: number | null) => void,
) => void

type OnOpenActionSheet = {
  buttons: ButtonSettings[]
  cancelName: string
  showActionSheetWithOptions: ShowActionSheetHook
  showActionSheetCb?: (buttonIndex?: number | null) => void
}

export const useActionSheetHandler = ({
  buttons,
  cancelName,
  showActionSheetWithOptions,
  showActionSheetCb,
}: OnOpenActionSheet) =>
  useCallback(() => {
    const options = [...buttons.map(e => e.name), cancelName]
    const cancelButtonIndex = options.length - 1
    const lastDesturctiveButton = buttons
      .map(e => e.destructive)
      .lastIndexOf(true)
    const destructiveButtonIndex =
      lastDesturctiveButton > -1 ? lastDesturctiveButton : undefined

    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        destructiveButtonIndex,
        options,
      },
      showActionSheetCb
        ? showActionSheetCb
        : (buttonIndex?: number | null) => {
            // Default behavior is to call buttonItems action field
            if (!isNil(buttonIndex) && buttonIndex !== cancelButtonIndex) {
              buttons[buttonIndex].action()
            }
          },
    )
  }, [buttons, cancelName, showActionSheetCb, showActionSheetWithOptions])
