import { SyncStorage } from 'src/utils/SyncStorage'
import { UserSession } from './type'

export interface UserPinSession {
  userId: string
  pinId: string
  name: string
  cognitoUserId?: string
}

const LOCAL_STORAGE_KEY = 'apc_state'

export const userPinSessionService = {
  // Sets the userPinSession in SyncStorage with userId as the key.
  setUserPinSession(userData: UserSession, devicePinId: string): void {
    const { userAttributes } = userData
    const userPinSession: UserPinSession = {
      pinId: devicePinId,
      userId: userAttributes.id,
      cognitoUserId: userAttributes.cognito_user_id,
      name: userAttributes.name,
    }

    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    const data = storedData ? JSON.parse(storedData) : {}
    data[userPinSession.userId] = userPinSession
    SyncStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
  },

  // Retrieves the userPinSession from SyncStorage using userId as the key.
  getUserPinSession(userId: string): UserPinSession | null {
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedData) {
      const data = JSON.parse(storedData) || {}
      return data[userId] || null
    }
    return null
  },

  getAllUserPinSession(): UserPinSession[] | null {
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedData) {
      const data = JSON.parse(storedData)
      return data || []
    }
    return []
  },

  // Deletes the userPinSession from SyncStorage using userId as the key.
  deleteUserPinSession(userId: string): void {
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedData) {
      const data = JSON.parse(storedData)
      delete data[userId]
      SyncStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
    }
  },

  hasPin(userId: string): boolean {
    const pinInfo = this.getUserPinSession(userId)
    return !!pinInfo?.pinId
  },
}
