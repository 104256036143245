import React from 'react'
import { View, StyleSheet } from 'react-native'
import { InvalidPasswordSvg } from 'src/design-system/components/Icon/InvalidPasswordIcon'
import { Paragraph } from 'src/design-system/components/Text'
import { Colors } from 'src/design-system/theme'

export const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <View style={styles.container}>
      <InvalidPasswordSvg size={16} color={Colors.Contents.negative} />
      <Paragraph size="S" style={styles.text}>
        {message}
      </Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    paddingVertical: 8,
    paddingHorizontal: 16,
    gap: 8,
    borderRadius: 4,
    backgroundColor: Colors.Backgrounds.negative,
  },
  text: { flex: 1, color: Colors.Contents.negativeOnColor },
})
