import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
  Platform,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { SvgClose, SvgCheck } from 'src/components/Icons'
import { ToastType } from './type'

const DEFAULT_DURATION = 5000

const colorMap = {
  success: '#7ED321',
  error: '#E02020',
  process: '#6D7278',
  notice: '#FA6400',
  disconnected: Colors.disabled,
  reload: '#007AFF',
  schedulerWarning: '#FA6400',
  noPermissionWarning: Colors.disabled,
}

export type Toast = {
  msg: string
  type: keyof typeof colorMap
  id?: string
  onClick?: (() => void) | null
  duration?: number
}

type Props = {
  toast: Toast
  onClose: (t: Toast) => void
}

export const Toast: React.FC<Props> = ({ toast, onClose }) => {
  useEffect(() => {
    // Failure or in process ones won't disappear automatically
    if (
      toast.type === ToastType.DISCONNECTED ||
      toast.type === ToastType.ERROR ||
      (toast.type === ToastType.PROCESS && !toast.duration) ||
      toast.type === ToastType.RELOAD ||
      toast.type === ToastType.SCHEDULER_WARNING
    ) {
      return
    }
    const timeoutID = setTimeout(close, toast.duration ?? DEFAULT_DURATION)
    return () => clearTimeout(timeoutID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [offset] = useState(new Animated.Value(0))
  const [opacity] = useState(new Animated.Value(0))
  useEffect(() => {
    Animated.parallel([
      Animated.timing(offset, {
        toValue: -40,
        duration: 300,
        useNativeDriver: Platform.OS !== 'web',
      }),
      Animated.timing(opacity, {
        toValue: 1,
        duration: 300,
        useNativeDriver: Platform.OS !== 'web',
      }),
    ]).start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const close = () => {
    Animated.parallel([
      Animated.timing(offset, {
        toValue: 0,
        duration: 300,
        useNativeDriver: Platform.OS !== 'web' ? true : false,
      }),
      Animated.timing(opacity, {
        toValue: 0,
        duration: 300,
        useNativeDriver: Platform.OS !== 'web' ? true : false,
      }),
    ]).start(() => onClose(toast))
  }

  return (
    <Animated.View
      style={[
        styles.container,
        {
          opacity,
          backgroundColor: colorMap[toast.type],
          transform: [
            {
              translateY: offset,
            },
          ],
        },
      ]}
    >
      <TouchableOpacity
        disabled={toast.type === 'disconnected'}
        onPress={close}
        testID="closeToast"
        style={styles.touchContainer}
      >
        {toast.type === 'process' && (
          <ActivityIndicator
            style={styles.spinner}
            size="small"
            color={Colors.white}
          />
        )}

        {toast.type === 'success' && (
          <View style={styles.check}>
            <SvgCheck />
          </View>
        )}

        <BoldableText>{toast.msg}</BoldableText>

        {toast.type !== 'disconnected' && (
          <View style={styles.close}>
            <SvgClose />
          </View>
        )}
      </TouchableOpacity>
    </Animated.View>
  )
}

type BoldableProps = {
  children: string
}
const BoldableText: React.FC<BoldableProps> = ({ children: message }) => {
  const starsMatch = message.match(/\*/g)
  // return regular text if no bold indicators * or uneven number of * found
  if (!starsMatch || starsMatch.length % 2 !== 0) {
    return <Text style={styles.msg}>{message}</Text>
  }
  // even index sections are regular, odd index sections are between '*'s
  const textSections = message.split('*')
  return (
    <Text style={styles.msg}>
      {textSections.map((words, i) => (
        <Text key={i} style={i % 2 !== 0 && styles.bold}>
          {words}
        </Text>
      ))}
    </Text>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderRadius: 5,
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  touchContainer: {
    flex: 1,
    minHeight: 45,
    minWidth: 280,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    flexDirection: 'row',
  },
  spinner: {
    paddingRight: 10,
  },
  msg: {
    flex: 1,
    fontFamily: Fonts.regular,
    fontSize: 13,
    color: Colors.white,
  },
  bold: {
    fontFamily: Fonts.bold,
  },
  check: {
    paddingRight: 10,
  },
  close: {
    marginLeft: 10,
  },
})
