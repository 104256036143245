import React, { useLayoutEffect, useState } from 'react'
import { View, StyleSheet, Pressable, Image } from 'react-native'
import { Layout } from '../components/Layout'
import { Heading, Label } from 'src/design-system/components/Text'
import { Button } from 'components/common/Button'
import { useNavigation } from '@react-navigation/native'
import { DividerWithText } from '../components/DividerWithText'
import { EmailTextInput } from 'src/design-system/components/TextInput/EmailTextInput'
import { PasswordTextInput } from 'src/design-system/components/TextInput/PasswordTextInput'
import { Colors, Typography } from 'src/design-system/theme'
import { useTranslation } from 'react-i18next'
import {
  Auth,
  AUTH_STATE,
  useAuth,
  userPinSessionService,
  userSessionService,
} from 'src/context/auth'
import { RuleStatus } from 'src/design-system/components/TextInput/TextInput'
import { InvalidStatus } from '../components/InvalidStatus'
import { PasswordAction } from 'src/context/auth/utils/type'
import { ErrorMessage } from '../components/ErrorMessage'

export const LoginWithEmail: React.FC = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const { toggleAuthState } = useAuth()
  const [email, setEmail] = useState<string>('')
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState<RuleStatus[] | null>(null)
  const [password, setPassword] = useState<string>('')
  const [isLocked, setIsLocked] = useState<boolean>(
    userSessionService.checkSignInLocked(),
  )

  const handleExistingPin = () => {
    const allUserPinSession = userPinSessionService.getAllUserPinSession()
    if (!allUserPinSession || allUserPinSession.length === 0) {
      return false
    }
    const allUserPinSessionArray = Object.values(allUserPinSession)
    return allUserPinSessionArray.filter(pin => !!pin.pinId).length > 0
  }

  const handleResetPassword = () => {
    navigation.navigate(AUTH_STATE.RESET_PASSWORD.toLowerCase())
  }

  const handleSignIn = async () => {
    const furtherAction = await Auth.signIn(email, password)
    if (furtherAction?.errorCode) {
      return setIsLocked(userSessionService.checkSignInLocked())
    }
    toggleAuthState(AUTH_STATE.LOGIN, true)
    let newAuthState = toggleAuthState(AUTH_STATE.RESET_PASSWORD, true)

    if (furtherAction) {
      if (
        furtherAction?.ChallengeName === PasswordAction.NEW_PASSWORD_REQUIRED
      ) {
        const session = furtherAction.Session ?? ''
        const userAttributes = JSON.parse(
          furtherAction?.ChallengeParameters?.userAttributes ?? '{}',
        )
        const email = userAttributes?.email || ''
        return navigation.navigate(newAuthState.toLowerCase(), {
          email,
          session,
          action: PasswordAction.NEW_PASSWORD_REQUIRED,
        })
      }
    }
    const session = userSessionService.getUserSession()
    const isPasswordExpired =
      new Date() >
      new Date(session?.userAttributes?.password_expired_at || Date.now())
    const isPasswordSkipped =
      new Date() <
      new Date(session?.userAttributes?.skip_change_pwd_exp || Date.now())
    // if password is not expired or skipped
    if (!isPasswordExpired || isPasswordSkipped) {
      newAuthState = toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
    }
    const isEmailVerified = session?.userAttributes?.email_verified
    const isEmailSkipped =
      new Date() <
      new Date(session?.userAttributes?.skip_verifyemail_exp || Date.now())
    if (isEmailVerified || isEmailSkipped) {
      newAuthState = toggleAuthState(AUTH_STATE.VERIFY_EMAIL, true)
    }
    navigation.navigate(newAuthState.toLowerCase())
  }

  const handleSignInWithPin = () => {
    navigation.navigate(AUTH_STATE.UNLOCK_WITH_PIN.toLowerCase())
  }

  useLayoutEffect(() => {
    const session = userSessionService.getUserSession()
    if (
      session?.authenticationResult?.accessToken &&
      session?.userAttributes?.id
    ) {
      toggleAuthState(AUTH_STATE.LOGIN, true)
      const newState2 = toggleAuthState(AUTH_STATE.RESET_PASSWORD, true)
      if (newState2 === AUTH_STATE.AUTHENTICATED) return
      navigation.navigate(newState2.toLowerCase())
    }
  }, [navigation, toggleAuthState])

  const LoginWithPin = () =>
    handleExistingPin() ? (
      <View style={styles.dividerContainer}>
        <DividerWithText text={t('login:label.alreadySignedIn')} />
        <Button
          style={styles.unlockBtn}
          containerStyle={styles.unlockBtnContainer}
          onPress={handleSignInWithPin}
          title={t('login:label.unlockWithPin')}
          textStyle={styles.unlockBtnText}
          ariaLabel={'UnlockWithPinBtn'}
        />
      </View>
    ) : null

  const ForgotPasswordButton = () => (
    <Pressable
      style={styles.forgotPasswordBtn}
      onPress={handleResetPassword}
      aria-label="ForgotPasswordBtn"
    >
      <Label size={'L'} style={styles.forgotPasswordText}>
        {t('login:label.forgotPassword')}
      </Label>
    </Pressable>
  )

  return (
    <Layout>
      <View style={styles.headingContainer}>
        <Image
          source={require('assets//images/vetradar-icon.png')}
          resizeMode="contain"
          style={styles.logoIcon}
        />
        <Heading size="XL">{t('login:label.signInWithEmail')}</Heading>
      </View>
      <View style={styles.formFieldContainer}>
        <EmailTextInput
          onValidation={setIsValidEmail}
          onBlur={() => setIsEmailDirty(true)}
          ariaLabel="EmailField"
          textChange={setEmail}
        />
        <View style={styles.passwordFieldContainer}>
          <PasswordTextInput
            onValidation={() => {}}
            onBlur={() => {}}
            textChange={setPassword}
            skipValidation={true}
            disabled={isLocked}
            ariaLabel="PasswordField"
          />
          {isValidEmail?.map(email =>
            !email.isValid && isEmailDirty ? (
              <InvalidStatus key={email.message} status={email.message} />
            ) : null,
          )}
          {isLocked ? (
            <ErrorMessage message={t('login:error.lockedAccount')} />
          ) : null}
        </View>
      </View>
      <ForgotPasswordButton />
      <Button
        style={styles.signInBtn}
        containerStyle={styles.signInBtnContainer}
        onPress={handleSignIn}
        disabled={isLocked}
        title={t('login:label.signIn')}
        textStyle={styles.signInBtnText}
        ariaLabel="SignInBtn"
      />
      <LoginWithPin />
    </Layout>
  )
}

const styles = StyleSheet.create({
  logoIcon: {
    width: 50,
    height: 50,
  },
  headingContainer: {
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: 48,
  },
  formFieldContainer: { width: '100%', gap: 32, marginBottom: 16 },
  passwordFieldContainer: { gap: 8 },
  forgotPasswordBtn: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 48,
  },
  forgotPasswordText: { color: Colors.Contents.accent },
  signInBtn: {
    width: '100%',
    backgroundColor: Colors.Backgrounds.accentPrimary,
    borderRadius: 4,
  },
  signInBtnContainer: { width: '100%', paddingHorizontal: 0 },
  signInBtnText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  dividerContainer: { marginTop: 48, gap: 24 },
  unlockBtn: {
    width: '100%',
    backgroundColor: Colors.Backgrounds.UI,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
    marginBottom: 24,
  },
  unlockBtnContainer: { width: '100%', paddingHorizontal: 0 },
  unlockBtnText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
    color: Colors.Contents.primary,
  },
})
