import React, { PropsWithChildren, useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Layout } from '../components/Layout'
import { useNavigation } from '@react-navigation/native'
import { Auth, AUTH_STATE, useAuth } from 'src/context/auth'
import { AuthHeader } from '../components/Header'
import { RuleStatus } from 'src/design-system/components/TextInput/TextInput'
import { Colors, Typography } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTranslation } from 'react-i18next'
import { SvgArrow } from 'components/Icons'
import { EmailTextInput } from 'src/design-system/components/TextInput/EmailTextInput'
import { InvalidStatus } from '../components/InvalidStatus'
import { Button } from 'components/common/Button'
import { environment } from 'src/config'
import { PasswordAction } from 'src/context/auth/utils/type'

export const ResetPassword: React.FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const { toggleAuthState } = useAuth()
  const { isWeb } = environment
  const [email, setEmail] = useState('')
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [emailValidators, setEmailValidators] = useState<RuleStatus[] | null>(
    null,
  )
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const exAndSmallScreen = isExSmallScreen || isSmallScreen
  const hasSecondBtn = isWeb || !exAndSmallScreen

  const handleSendCode = async () => {
    if (emailValidators?.some(email => !email.isValid)) return
    await Auth.forgetPassword(email)
    toggleAuthState(AUTH_STATE.RESET_PASSWORD, true)
    navigation.navigate(AUTH_STATE.CHANGE_PASSWORD.toLowerCase(), {
      email,
      action: PasswordAction.FORGET_PASSWORD,
    })
  }

  const handleBack = () => navigation.goBack()

  return (
    <Layout>
      {exAndSmallScreen ? (
        <TouchableOpacity
          style={styles.backArrowBackground}
          onPress={() => handleBack()}
        >
          <SvgArrow
            orient="left"
            color={Colors.Contents.primary}
            width={15}
            height={15}
          />
        </TouchableOpacity>
      ) : null}
      <AuthHeader
        title={t('login:label.resetPassword')}
        hasVRLogo={!exAndSmallScreen}
        style={{
          marginBottom: 32,
        }}
      />
      <EmailTextInput
        onValidation={setEmailValidators}
        onBlur={() => setIsEmailDirty(true)}
        textChange={setEmail}
        ariaLabel="EmailField"
      />
      <View style={styles.invalidEmailMsg}>
        {emailValidators?.map(email =>
          !email.isValid && isEmailDirty ? (
            <InvalidStatus key={email.message} status={email.message} />
          ) : null,
        )}
      </View>
      <View
        style={[
          styles.btnContainer,
          !hasSecondBtn ? styles.exAndSmallBtnContainer : null,
        ]}
      >
        <Button
          style={styles.btnStyle}
          containerStyle={styles.btnContainerStyle}
          textStyle={styles.btnText}
          onPress={handleSendCode}
          title={t('login:pin.sendCode')}
        />
        {hasSecondBtn ? (
          <Button
            style={[styles.btnStyle, styles.subBtnStyle]}
            containerStyle={styles.btnContainerStyle}
            textStyle={[styles.btnText, styles.backBtnColor]}
            onPress={handleBack}
            title={t('login:pin.backToSign')}
          />
        ) : null}
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  backArrowBackground: {
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
  },
  invalidEmailMsg: {
    marginTop: 5,
    height: 17,
  },
  btnStyle: {
    width: '100%',
    backgroundColor: Colors.Backgrounds.accentPrimary,
  },
  subBtnStyle: {
    backgroundColor: Colors.Backgrounds.UI,
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  btnText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  backBtnColor: { color: Colors.Contents.primary },
  btnContainer: {
    borderRadius: 4,
    height: 48,
    marginTop: 16,
    gap: 16,
  },
  btnContainerStyle: { width: '100%', paddingHorizontal: 0 },
  exAndSmallBtnContainer: {
    marginTop: 520,
    backgroundColor: Colors.Backgrounds.accentPrimary,
  },
})
