import AsyncStorage from '@react-native-async-storage/async-storage'
import { SyncStorage } from 'src/utils/SyncStorage'
import { UserSession, UserSessionAPIData } from './type'
import { Auth, authTypeService, ONE_MIN } from './index'
import { AUTH_WEIGHT } from '../auth'
import { pinSwitchService } from 'src/utils/pinSwitchService'

const LOCAL_STORAGE_KEY = 'apc_user_session'

export const userSessionService = {
  userSession: {} as UserSession,

  async init() {
    const sessionData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    try {
      this.userSession = sessionData ? JSON.parse(sessionData) : {}
      const isAuthV2 = authTypeService.getIsAuthV2()
      const hasV2Session = this.hasV2Session()
      if (!isAuthV2 || hasV2Session) return
      // If isAuthV2 is true and not hasV2Session, try to initialize session via local refreshToken
      const keys = await AsyncStorage.getAllKeys()
      const v1AuthRegex = /users|CognitoIdentityServiceProvider/
      const v1AuthKeys = keys.filter(key => v1AuthRegex.test(key))
      const refreshTokenKey = v1AuthKeys.find(key =>
        key.includes('refreshToken'),
      )
      if (!refreshTokenKey) return
      const refreshToken = await AsyncStorage.getItem(refreshTokenKey)
      if (!refreshToken) return
      await Auth.refreshToken(refreshToken)
      await Auth.getUserInfo()
      await AsyncStorage.multiRemove(v1AuthKeys)
      if (v1AuthKeys.length > 0) pinSwitchService.unInit()
      userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
    } catch (e) {
      this.userSession = {} as UserSession
    }

    this.persist()
  },

  setUserSession(apiData: UserSessionAPIData): void {
    const { authenticationResult, userInfo, pinInfo } = apiData

    if (userInfo) {
      const unlockAt = this.userSession?.userAttributes?.unlock_sign_in_at
      const unlockAtServer = userInfo?.unlock_sign_in_at
      const useServer =
        !unlockAt ||
        !unlockAtServer ||
        new Date(unlockAt) < new Date(unlockAtServer)
      userInfo.unlock_sign_in_at = useServer ? unlockAtServer : unlockAt
    }

    this.userSession = {
      ...this.userSession,
      ...(pinInfo && { pinInfo }),
      ...(authenticationResult && { authenticationResult }),
      ...(userInfo && { userAttributes: userInfo }),
    }

    this.persist()
  },

  setAuthWeight(authWeight: number) {
    this.userSession = {
      ...this.userSession,
      authWeight,
    }
    this.persist()
  },

  increaseAuthFailures(offset = 0) {
    const authFailures =
      Number(this.userSession?.authFailures ?? 0) + 1 + offset
    this.userSession = {
      ...this.userSession,
      authFailures,
    }
    if (authFailures > 10) {
      const unLockAt = new Date(
        new Date().getTime() + 30 * ONE_MIN,
      ).toISOString()
      this.userSession = {
        ...this.userSession,
        userAttributes: {
          ...this.userSession.userAttributes,
          unlock_sign_in_at: unLockAt,
        },
      }
    }
    this.persist()
  },

  checkSignInLocked(): boolean {
    const unlockAt = this.userSession?.userAttributes?.unlock_sign_in_at
    if (!unlockAt) return false
    const isLocked = new Date() < new Date(unlockAt)
    if (!isLocked) {
      this.userSession = {
        ...this.userSession,
        authFailures: 0,
        userAttributes: {
          ...this.userSession.userAttributes,
          unlock_sign_in_at: '',
        },
      }
      this.persist()
    }
    return isLocked
  },

  getUserOrgIds(skipTraining = true): string[] {
    const attributes = this.userSession?.userAttributes
    if (!attributes || !Array.isArray(attributes['cognito:groups'])) return []
    return (
      attributes['cognito:groups']
        .filter(
          (group: string) => !skipTraining || !group.includes('Training_-'),
        )
        .map((group: string) => group.split('|')[0]) ?? []
    )
  },

  getAuthWeight(): number {
    return Number(this.userSession?.authWeight ?? 0)
  },

  getUserSession(): UserSession | null {
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedData) {
      return JSON.parse(storedData)
    }
    return null
  },

  deleteUserSession(): void {
    SyncStorage.removeItem(LOCAL_STORAGE_KEY)
    this.userSession = {} as UserSession
  },

  hasV2Session(): boolean {
    return !!this.getUserSession()?.userAttributes?.id
  },

  persist() {
    if (!this.userSession || Object.keys(this.userSession).length === 0) return
    SyncStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.userSession))
  },
}
