import React, { useCallback, useMemo, useState } from 'react'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { Avatar } from 'components/common/Avatar'
import { SoftLogout } from 'components/SoftLogout'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { environment } from 'src/config'
import { useDeviceLock } from 'src/context/deviceLock/deviceLock'
import { useUser } from 'src/context/user'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useSignOut } from 'src/hooks/useSignOut'
import { useActionSheetHandler } from 'src/utils/useActionSheetHandler'
import { useHelp } from 'src/context/help/help'

const { isWeb } = environment

export const AccountButton: React.FC = React.memo(() => {
  const { t } = useTranslation()
  const { username, user } = useUser()
  const { isLargeScreen } = useBreakpoint()
  const { signOut } = useSignOut()
  const { onSelectUnlockedUser, lock } = useDeviceLock()

  const [showAccountDropdown, setShowAccountDropdown] = useState(false)
  const hideDropdownMenu = useCallback(() => setShowAccountDropdown(false), [])

  const showVerifyPinDialog = useCallback(() => {
    onSelectUnlockedUser(user?.email ?? '')
    lock()
    setShowAccountDropdown(false)
  }, [lock, onSelectUnlockedUser, user?.email])

  const { showActionSheetWithOptions } = useActionSheet()
  const { toggleHelpModal } = useHelp()

  const softLogoutOptions = useMemo(
    () => [
      {
        action: showVerifyPinDialog,
        name: t('account:general.lock'),
      },
      {
        action: toggleHelpModal,
        name: t('account:general.help'),
      },
      {
        action: signOut,
        name: t('account:general.signOut'),
      },
    ],
    [showVerifyPinDialog, t, toggleHelpModal, signOut],
  )

  const softLogoutOptionsHandler = useActionSheetHandler({
    showActionSheetWithOptions,
    buttons: softLogoutOptions,
    cancelName: t('general.cancel'),
  })
  const isLargeScreenOrIOS = !isWeb || isLargeScreen

  const toggleSoftLogout = useCallback(() => {
    // using portal
    if (isLargeScreenOrIOS) {
      setShowAccountDropdown(menuState => !menuState)
      return
    }
    // using actions menu
    softLogoutOptionsHandler()
  }, [isLargeScreenOrIOS, softLogoutOptionsHandler, setShowAccountDropdown])

  return (
    <TouchableOpacity
      accessibilityLabel={'account'}
      testID={'IconButton-account'}
      onPress={toggleSoftLogout}
      style={[
        styles.avatarButton,
        // add extra marginRight when in large web header
        isWeb && isLargeScreen && styles.largeScreenMarginRight,
      ]}
    >
      <Avatar name={username} size={40} radius={20} />
      {isLargeScreenOrIOS && showAccountDropdown ? (
        <SoftLogout
          username={username}
          signOut={signOut}
          hideDropdownMenu={hideDropdownMenu}
          showVerifyPinDialog={showVerifyPinDialog}
        />
      ) : null}
    </TouchableOpacity>
  )
})

AccountButton.displayName = 'AccountButton'

const styles = StyleSheet.create({
  avatarButton: {
    alignItems: 'center',
    marginRight: isWeb ? 30 : 0,
  },
  largeScreenMarginRight: {
    marginRight: 30,
  },
})
