export enum Routes {
  PatientsStack = 'PatientsStack',
  WhiteboardsStack = 'WhiteboardsStack',
  SettingsStack = 'SettingsStack',
  AccountStack = 'AccountStack',
  Account = 'Account',
  AddCustomProduct = 'AddCustomProduct',
  AddPatient = 'AddPatient',
  AddTemplate = 'AddTemplate',
  AddUser = 'AddUser',
  CustomProductList = 'CustomProductList',
  EditCustomProduct = 'EditCustomProduct',
  EditPatient = 'EditPatient',
  EditTemplate = 'EditTemplate',
  EditProcedure = 'EditProcedure',
  EditUser = 'EditUser',
  Manifest = 'Manifest',
  Messages = 'Messages',
  AddSheet = 'AddSheet',
  Patients = 'Patients',
  Settings = 'Settings',
  SetupConditionalFields = 'SetupConditionalFields',
  SetupProductField = 'SetupProductField',
  Sheet = 'Sheet',
  SheetList = 'SheetList',
  Tasks = 'Tasks',
  WhiteboardList = 'WhiteboardList',
  Whiteboard = 'Whiteboard',
  TreatmentTemplateList = 'TreatmentTemplateList',
  ProceduresList = 'ProceduresList',
  WorkflowTemplateList = 'WorkflowTemplateList',
  AddWorkflowTemplate = 'AddWorkflowTemplate',
  EditWorkflowTemplate = 'EditWorkflowTemplate',
  CallParameterTemplateList = 'CallParameterTemplateList',
  AddCallParameterTemplate = 'AddCallParameterTemplate',
  EditCallParameterTemplate = 'EditCallParameterTemplate',
  UserList = 'UserList',
  Drugs = 'EmergencyDrugs',
  ColorsConfig = 'ColorsConfig',
  SheetGroupOrderConfig = 'SheetGroupOrderConfig',
  StaffedHoursConfig = 'StaffedHoursConfig',
  TreatmentFrequenciesConfig = 'TreatmentFrequenciesConfig',
  RouteOfAdministrationConfig = 'RouteOfAdministrationConfig',
  DataSync = 'DataSync',
  ChangePassword = 'ChangePassword',
}
