import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'components/common'
import { Modal } from 'components/common/Modal'
import { View, StyleSheet } from 'react-native'
import { Heading, Paragraph } from 'src/design-system/components/Text'
import { Colors, Typography } from 'src/design-system/theme'
import { environment } from 'src/config'
import { VRBottomSheet } from 'components/BottomSheet'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/user'
import { differenceInDays } from 'date-fns'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  authTypeService,
  useAuth,
  userSessionService,
} from 'src/context/auth'
import { PasswordAction, SkipAuthType } from 'src/context/auth/utils/type'
import { useNavigation } from '@react-navigation/native'

interface ExpirationPopupProps {}

const ExpirationPopup: React.FC<ExpirationPopupProps> = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { toggleAuthState } = useAuth()
  const navigation = useNavigation()

  const [visible, setVisible] = useState(false)
  const [daysToExpiry, setDaysToExpiry] = useState<number>()
  const bottomSheetRef = useRef<BottomSheetModal>(null)

  // Calculate the days until the password expires
  useEffect(() => {
    if (user?.password_expired_at) {
      const expiresAt = new Date(user.password_expired_at)
      const today = new Date()
      const daysRemaining = differenceInDays(expiresAt, today)
      setDaysToExpiry(daysRemaining)
    }
  }, [user])

  // If the warning isn't currently skipped, show the modal.
  useEffect(() => {
    if (daysToExpiry !== undefined) {
      const skippedUntil = new Date(user?.skip_change_pwd_exp ?? 0)
      const today = new Date()
      if (daysToExpiry <= 5 && skippedUntil < today) {
        setVisible(true)
      }
    }
  }, [user, daysToExpiry])

  // Handle the IOS BottomSheet
  useEffect(() => {
    if (visible) {
      bottomSheetRef?.current?.present()
    } else {
      bottomSheetRef?.current?.close()
    }
  }, [visible, bottomSheetRef])

  const onClose = () => {
    setVisible(false)
  }

  const onAskLater = () => {
    Auth.skipAuth(SkipAuthType.CHANGE_PASSWORD_WARNING)
    setVisible(false)
  }

  const onChangePass = () => {
    userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
    const newAuthState = toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, false)
    navigation.navigate(newAuthState.toLowerCase(), {
      email: userSessionService.getUserSession()?.userAttributes?.email,
      action: PasswordAction.CHANGE_PASSWORD_IN_SESSION,
    })
    setVisible(false)
  }

  // Handle FeatureFlag
  const isAuthV2 = authTypeService.getIsAuthV2()
  if (!isAuthV2) return null

  if (!environment.isWeb) {
    return (
      <VRBottomSheet
        ref={bottomSheetRef}
        snapPoints={['40%']}
        initialSnapIndex={0}
      >
        <View style={[styles.body]}>
          <Heading size="L">{t('login:passwordExpiration.title')}</Heading>
          <Paragraph size="M">
            {t('login:passwordExpiration.body', { count: daysToExpiry })}
          </Paragraph>
        </View>
        <View
          style={[
            styles.footer,
            { flexDirection: 'column', paddingBottom: 32 },
          ]}
        >
          <ChangePassButton
            title={t('login:passwordExpiration.changePassword')}
            onPress={onChangePass}
          />
          <SkipButton
            title={t('login:passwordExpiration.skip')}
            onPress={onAskLater}
          />
        </View>
      </VRBottomSheet>
    )
  }

  if (!visible) return null // Fix the issue with the modal not showing up on the web, because it initially renders as false, and adds a timeout on closing the modal.
  return (
    <Modal visible={visible} onCloseModal={onClose} contentStyle={styles.modal}>
      <View
        style={[
          styles.body,
          { borderBottomWidth: 1, borderColor: Colors.Borders.primary },
        ]}
      >
        <Heading size="S">{t('login:passwordExpiration.title')}</Heading>
        <Paragraph size="S">
          {t('login:passwordExpiration.body', { count: daysToExpiry })}
        </Paragraph>
      </View>
      <View style={styles.footer}>
        <SkipButton
          title={t('login:passwordExpiration.skip')}
          onPress={onAskLater}
        />
        <ChangePassButton
          title={t('login:passwordExpiration.changePassword')}
          onPress={onChangePass}
        />
      </View>
    </Modal>
  )
}

const ChangePassButton: React.FC<{ title: string; onPress: () => void }> = ({
  title,
  onPress,
}) => {
  return (
    <Button
      style={styles.changePassBtn}
      containerStyle={styles.btnContainer}
      contentStyle={styles.btnContent}
      textStyle={styles.text}
      a11yLabel="Change password"
      ariaLabel="Change password"
      title={title}
      onPress={onPress}
    />
  )
}

const SkipButton: React.FC<{ title: string; onPress: () => void }> = ({
  title,
  onPress,
}) => {
  return (
    <Button
      style={styles.skipBtn}
      containerStyle={styles.btnContainer}
      contentStyle={styles.btnContent}
      textStyle={[styles.text, { color: Colors.Contents.primary }]}
      a11yLabel="Ask me later"
      ariaLabel="Ask me later"
      title={title}
      onPress={onPress}
    />
  )
}

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    width: 360,
    bottom: 52,
    right: 52,
    transform: [],
  },
  body: {
    padding: 16,
    gap: 8,
    flex: 1,
  },
  footer: {
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
  },
  changePassBtn: {
    backgroundColor: Colors.Backgrounds.accentPrimary,
    borderRadius: 4,
    width: '100%',
  },
  skipBtn: {
    backgroundColor: Colors.Backgrounds.UI,
    borderRadius: 4,
    width: '100%',
  },
  btnContainer: {
    paddingHorizontal: 0,
  },
  btnContent: {
    paddingHorizontal: 16,
    paddingVertical: environment.isWeb ? 8 : 16,
  },
  text: {
    ...Typography.Label.M,
    fontWeight: Typography.FontWeights.bold,
    lineHeight: 17,
  },
})

export default ExpirationPopup
