import { Storage } from '../utils/Storage'

const DEVICE_LOCK_STORAGE_NAME = 'device_lock'
const IS_DEVICE_LOCK_KEY_NAME = 'is_locked'

export class DeviceLockService {
  private storage: Storage

  constructor() {
    this.storage = new Storage(DEVICE_LOCK_STORAGE_NAME)
  }

  public lock(): void {
    this.storage.setItem(IS_DEVICE_LOCK_KEY_NAME, true)
  }

  public unlock(): void {
    this.storage.setItem(IS_DEVICE_LOCK_KEY_NAME, false)
  }

  public isLocked(): boolean {
    return this.storage.getItem(IS_DEVICE_LOCK_KEY_NAME) ?? false
  }
}

export const deviceLockService = new DeviceLockService()
