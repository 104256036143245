import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { GET_SHEET } from 'components/Sheet/graphql'
import { BULK_CREATE_TREATMENTS } from 'components/Treatment/graphql'
import { FormData } from 'components/TreatmentForm'
import { getTreatmentInput } from 'components/TreatmentForm/utils/getTreatmentInput'
import { useTranslation } from 'react-i18next'
import { useConfirm } from 'src/context/confirm'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import {
  bulkCreateTreatments as BulkCreateTreatments,
  bulkCreateTreatmentsVariables as BulkCreateTreatmentsVariables,
} from 'src/types/bulkCreateTreatments'
import { getSheet, getSheetVariables } from 'src/types/getSheet'
import { TreatmentGroupFields } from 'src/types/TreatmentGroupFields'
import {
  BulkCreateTreatmentsInput,
  CreateTreatmentOptions,
} from 'types/globalTypes'

import { convertProductToOptimisticTreatmentGroup } from './convertProductToOptimisticTreatmentGroup'
import { getHasProductDuplication } from './productDuplicates'
import { ProductInfo } from './useCreateTreatment'
import { useUpdateTreatments } from './useUpdateTreatments'

export type BulkCreateTreatmentsRawInput = {
  formData: FormData
  product: ProductInfo
  options: CreateTreatmentOptions
}

export const useBulkCreateTreatments = (
  sheetId: string,
  patientId: string,
  conditionalTreatmentId?: string,
) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const client = useApolloClient()
  const updateTreatments = useUpdateTreatments(sheetId)
  const { fromToQueryDate } = useTimeResolution()

  const [bulkCreateTreatments] = useMutation<
    BulkCreateTreatments,
    BulkCreateTreatmentsVariables
  >(BULK_CREATE_TREATMENTS, {
    onCompleted: data => {
      data.bulkCreateTreatments.forEach(treatment =>
        updateTreatments(treatment),
      )
      toast.success(t('addTreatment:treatmentsAdded'))
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  const confirm = useConfirm()

  return async (
    bulkCreateTreatmentsRawInputs: BulkCreateTreatmentsRawInput[],
  ) => {
    if (!bulkCreateTreatmentsRawInputs.length) {
      return
    }

    const query = GET_SHEET
    const variables = {
      id: sheetId,
      organisation_id: organisationId,
      ...fromToQueryDate,
    }
    const sheetData = client.readQuery<getSheet, getSheetVariables>({
      query,
      variables,
    })

    const optimisticTreatmentGroups: TreatmentGroupFields[] = []
    const bulkCreateTreatmentsInputs: BulkCreateTreatmentsInput[] = []
    const duplicateOptimisticTreatmentGroups: TreatmentGroupFields[] = []
    const treatmentDuplicates: BulkCreateTreatmentsInput[] = []

    bulkCreateTreatmentsRawInputs.forEach(({ formData, product, options }) => {
      const { productId, productType } = product

      const treatmentInput = getTreatmentInput(formData, productType)

      const input = {
        ...treatmentInput,
        organisation_id: organisationId,
        patient_id: patientId,
        product_id: productId,
        sheet_id: sheetId,
        name: treatmentInput.name ?? product.productName,
      }

      const hasDuplication = getHasProductDuplication(sheetData, [productId])

      if (hasDuplication) {
        treatmentDuplicates.push({ input, options })
        duplicateOptimisticTreatmentGroups.push(
          convertProductToOptimisticTreatmentGroup(product),
        )
      } else {
        bulkCreateTreatmentsInputs.push({ input, options })
        optimisticTreatmentGroups.push(
          convertProductToOptimisticTreatmentGroup(product),
        )
      }
    })

    let ignoreDuplication = false
    if (treatmentDuplicates.length > 0) {
      try {
        let text = t('addTreatment:bulkDuplicationText')
        if (treatmentDuplicates.length === 1) {
          text = t('addTreatment:duplicationText', {
            treatmentName: treatmentDuplicates[0].input.name,
          })
        }
        await confirm({
          title: t('addTreatment:duplicationTitle'),
          text,
          okText: 'Yes',
          cancelText: 'No',
        })
        ignoreDuplication = false
      } catch (e) {
        ignoreDuplication = true
      }
    }

    if (bulkCreateTreatmentsInputs.length > 0 || !ignoreDuplication) {
      const dismiss = toast.process(t('addTreatment:treatmentsAdding'))
      bulkCreateTreatments({
        variables: {
          input: ignoreDuplication
            ? bulkCreateTreatmentsInputs
            : bulkCreateTreatmentsInputs.concat(treatmentDuplicates),
          ...fromToQueryDate,
          options: {
            conditional_treatment_id: conditionalTreatmentId,
            patient_id: patientId,
          },
        },
        fetchPolicy: 'no-cache',
        optimisticResponse: {
          bulkCreateTreatments: ignoreDuplication
            ? optimisticTreatmentGroups
            : optimisticTreatmentGroups.concat(
                duplicateOptimisticTreatmentGroups,
              ),
        },
        update: (_, { data }) => {
          if (data?.bulkCreateTreatments) {
            data.bulkCreateTreatments.forEach(treatment =>
              updateTreatments(treatment),
            )
          }
        },
      }).finally(dismiss)
    }
    return
  }
}
