/* eslint-disable no-bitwise */
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
  useCallback,
} from 'react'
import { Auth, authTypeService, userSessionService } from './utils'
import { LoadingConditionalRender } from 'components/common/LoadingOverlay'

export enum AUTH_STATE {
  AUTHENTICATED = 'AUTHENTICATED',
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  SET_UP_PIN = 'SET_UP_PIN',
  UNLOCK_WITH_PIN = 'UNLOCK_WITH_PIN',
}

export enum AUTH_WEIGHT {
  AUTHENTICATED = 0b111111, // 63
  LOGIN = 0b100000, // 32
  RESET_PASSWORD = 0b010000, // 16
  CHANGE_PASSWORD = 0b001000, // 8
  VERIFY_EMAIL = 0b000100, // 4
  SET_UP_PIN = 0b000010, // 2
  UNLOCK_WITH_PIN = 0b000001, // 1
}

// order: ["AUTHENTICATED", "LOGIN", "RESET_PASSWORD", "CHANGE_PASSWORD", "VERIFY_EMAIL", "SET_UP_PIN", "UNLOCK_WITH_PIN"]
const authStateCheckOrders = (Object.keys(AUTH_STATE) as AUTH_STATE[]).sort(
  (a, b) => AUTH_WEIGHT[b] - AUTH_WEIGHT[a],
)

const addWeight = (authWeight: number, weight: number): number =>
  authWeight | weight
const removeWeight = (authWeight: number, weight: number): number =>
  authWeight & ~weight

// Convert the weight to state that matches the largest AUTH_WEIGHT
// eg: if weight is 0b1110011, then the state is VERIFY_EMAIL not SET_UP_PIN or (VERIFY_EMAIL and SET_UP_PIN)
const mapAuthState = (weight: number) => {
  if (weight === AUTH_WEIGHT.AUTHENTICATED) return AUTH_STATE.AUTHENTICATED
  if (
    !Number.isInteger(weight) ||
    weight > AUTH_WEIGHT.AUTHENTICATED ||
    weight < AUTH_WEIGHT.UNLOCK_WITH_PIN
  )
    return AUTH_STATE.LOGIN
  return (
    authStateCheckOrders.find(
      state => (~weight & AUTH_WEIGHT[state]) === AUTH_WEIGHT[state],
    ) ?? AUTH_STATE.LOGIN
  )
}

interface AuthContextData {
  authState: AUTH_STATE
  toggleAuthState: (action: AUTH_STATE, completed: boolean) => AUTH_STATE
}

const AuthContext = createContext<AuthContextData | undefined>(undefined)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [authState, setAuthState] = useState<AUTH_STATE>(
    mapAuthState(userSessionService.getAuthWeight()),
  )

  useEffect(() => {
    const loadStoredAuth = async () => {
      await Auth.checkAuthVersion()
      const isAuthV2 = authTypeService.getIsAuthV2()
      if (isAuthV2) {
        await userSessionService.init()
        setAuthState(mapAuthState(userSessionService.getAuthWeight()))
      }
      setIsLoading(false)
    }

    loadStoredAuth()
  }, [])

  const toggleAuthState = useCallback(
    (state: AUTH_STATE, completed: boolean) => {
      const weight = AUTH_WEIGHT[state]
      const authWeight = userSessionService.getAuthWeight()
      const newAuthWeight = completed
        ? addWeight(authWeight, weight)
        : removeWeight(authWeight, weight)
      const newAuthState = mapAuthState(newAuthWeight)
      userSessionService.setAuthWeight(newAuthWeight)
      setAuthState(newAuthState)
      return newAuthState
    },
    [],
  )

  return (
    <AuthContext.Provider value={{ authState, toggleAuthState }}>
      <LoadingConditionalRender isLoading={isLoading}>
        {children}
      </LoadingConditionalRender>
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
