import React, { PropsWithChildren } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import { Colors } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isVeryLargeScreen, isExSmallScreen } = useBreakpoint()

  const IdexxLogo = () => (
    <Image
      source={require('assets/images/idexx-logo.png')}
      resizeMode="contain"
      style={styles.logoImage}
    />
  )

  if (isVeryLargeScreen) {
    return (
      <View style={styles.container}>
        <View style={styles.formContainer}>
          <IdexxLogo />
          <View style={styles.childrenContainer}>{children}</View>
        </View>
        <View style={styles.heroContainer}>
          <Image
            source={require('assets/images/dog-on-rocket.png')}
            resizeMode="contain"
            style={styles.heroImage}
          />
        </View>
      </View>
    )
  }

  if (!isExSmallScreen) {
    return (
      <View style={[styles.mediumContainer, styles.centeredContent]}>
        <IdexxLogo />
        <View style={styles.mediumChildrenContainer}>{children}</View>
      </View>
    )
  }

  return <View style={styles.smallContainer}>{children}</View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  heroContainer: {
    flex: 1,
    backgroundColor: '#1F284D',
    justifyContent: 'center',
  },
  heroImage: {
    flex: 1,
    maxHeight: 400,
  },
  logoImage: {
    width: 121,
    height: 23,
    position: 'absolute',
    top: 36,
    left: 36,
  },
  formContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.Backgrounds.UI,
    justifyContent: 'center',
    alignItems: 'center',
  },
  childrenContainer: {
    flexGrow: 1,
    maxWidth: 504,
  },
  mediumContainer: {
    flex: 1,
    flexDirection: 'row',
    padding: 24,
    backgroundColor: Colors.Backgrounds.UI,
  },
  centeredContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediumChildrenContainer: {
    flexGrow: 1,
    maxWidth: 504,
  },
  smallContainer: {
    padding: 24,
    justifyContent: 'center',
    backgroundColor: Colors.Backgrounds.UI,
    flex: 1,
  },
})
