import React from 'react'
import {
  ActivityIndicator,
  FlexStyle,
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { Layout } from 'src/constants/Layout'
import { useBreakpoint } from 'src/hocs/breakpoint'

export type MinWidth =
  | {
      small?: FlexStyle['minWidth']
      large?: FlexStyle['minWidth']
    }
  | undefined

type Props = {
  a11yLabel?: string
  minWidth?: MinWidth
  onPress: (event: GestureResponderEvent) => void
  title: string
  testID?: string
  style?: StyleProp<ViewStyle>
  // Must be in hex format
  color?: string
  disabled?: boolean
  loading?: boolean
  textStyle?: StyleProp<TextStyle>
  contentStyle?: StyleProp<TextStyle>
  containerStyle?: StyleProp<ViewStyle>
  disabledBackgroundColor?: string
  ariaLabel?: string
}

export const Button: React.FC<Props> = ({
  a11yLabel,
  title,
  onPress,
  testID,
  style,
  color = Colors.blue,
  minWidth = {},
  disabled = false,
  loading = false,
  textStyle,
  contentStyle,
  containerStyle,
  disabledBackgroundColor = Colors.disabled,
  ariaLabel,
}) => {
  const breakpoint = useBreakpoint()
  const { small = '100%', large = 'auto' } = minWidth
  const minWidthValue = breakpoint.width >= Layout.md ? large : small

  return (
    <View style={[styles.container, containerStyle]}>
      <TouchableOpacity
        accessibilityLabel={a11yLabel || title}
        disabled={disabled || loading}
        onPress={onPress}
        aria-label={ariaLabel}
        style={[
          styles.button,
          { backgroundColor: color },
          { minWidth: minWidthValue },
          disabled && { backgroundColor: disabledBackgroundColor },
          loading && { backgroundColor: `${color}99` }, // A Workaround. Can't use {opacity: 0.6} on TouchableOpacity.
          style,
        ]}
        testID={testID}
      >
        <View style={[styles.content, contentStyle]}>
          <Text style={[styles.text, textStyle]}>{title}</Text>
          {loading ? (
            <ActivityIndicator
              size="small"
              color={Colors.white}
              style={styles.activityIndicator}
              testID="Button Activity Indicator"
            />
          ) : null}
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    paddingLeft: 10,
  },
  container: {
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  button: {
    alignItems: 'center',
    borderRadius: 8,
  },
  content: {
    flexDirection: 'row',
    paddingHorizontal: 25,
  },
  text: {
    color: Colors.white,
    fontFamily: Fonts.regular,
    fontSize: 17,
    lineHeight: 50,
  },
})

// Used in a couple places, but not sure if we should make it the default yet - BM
export const commonButtonMinWidth: MinWidth = {
  small: '90%',
  large: 350,
}
