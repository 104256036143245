import * as Sentry from '@sentry/react-native'
import * as SentryBrowser from '@sentry/browser'
import { environment } from 'src/config'
import type { Scope as ScopeNative } from '@sentry/react-native'
import type { Scope as ScopeBrowser } from '@sentry/browser'
import { identity } from 'lodash'

export { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
// nb: '@sentry/x' packages are child dependencies of sentry-expo, not explicitly in package.json

export const SentryExpo = environment.isWeb ? SentryBrowser : Sentry

export type Scope = ScopeBrowser | ScopeNative

export const sentryWrapper = environment.isWeb ? identity : Sentry.wrap

export const ignoreErrors = [
  'Context creation failed: jwt expired',
  'Context creation failed: no token provided',
  'no token provided',
  'SecurityError: Failed to register a ServiceWorker for scope',
  'SecurityError: MIME Type is not a JavaScript MIME type',
  // Firefox service-worker issue on init https://sentry.io/organizations/vet-radar/issues/2402127250
  'SecurityError: The operation is insecure.',
  // fontfaceError on load https://sentry.io/organizations/vet-radar/issues/2402476252
  '3000ms timeout exceeded',
  // Useless Noisy Errors, https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/#httpclient Can provide more info once Expo version bumped.
  'TypeError: Failed to fetch',
  'TypeError: Network request failed',
]

export const tracingOrigins = [
  'localhost',
  /api(\.master|\.staging)?\.vetradar.com/,
  /^\//,
]

export const initCommons = {
  ignoreErrors,
  enableInExpoDevelopment: false,
  environment: environment.releaseStage,
  // Sentry will try to print out useful debugging information if something goes
  // wrong with sending an event. Set this to `false` in production.
  debug: !environment.isProduction,
  sampleRate: 0.4,
  tracesSampleRate: 0.1,
}
