import React, { useEffect, useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/native'
import { Auth, AUTH_STATE, useAuth, userSessionService } from 'src/context/auth'
import { Layout } from '../components/Layout'
import { AuthHeader } from '../components/Header'
import { PasswordInput } from '../components/PasswordInput'
import { useTranslation } from 'react-i18next'
import { Colors, Typography } from 'src/design-system/theme'
import { Button } from 'components/common/Button'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { environment } from 'src/config'
import { SvgArrow } from 'components/Icons'
import { AuthStackParamList } from '.'
import { Routes } from 'constants/Routes'
import { Label } from 'src/design-system/components/Text/Label'
import { TextInput } from 'src/design-system/components/TextInput/TextInput'
import { ErrorCode, PasswordAction } from 'src/context/auth/utils/type'
import { PasswordTextInput } from 'src/design-system/components/TextInput/PasswordTextInput'

type Props = StackScreenProps<AuthStackParamList, Routes.ChangePassword>

export const ChangePassword: React.FC<Props> = ({ route }) => {
  const { toggleAuthState } = useAuth()
  const { t } = useTranslation()
  const navigation = useNavigation()
  const [title, setTitle] = useState<string>(t('login:label.changePassword'))
  const [action, setAction] = useState<PasswordAction>(
    PasswordAction.CHANGE_PASSWORD_IN_SESSION,
  )
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [session, setSession] = useState<string>('')
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const { isWeb } = environment
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const exAndSmallScreen = isExSmallScreen || isSmallScreen
  const [hasBackBtn, setHasBackBtn] = useState<boolean>(
    isWeb || !exAndSmallScreen,
  )

  useEffect(() => {
    if (route?.params) {
      setEmail(route.params.email ?? '')
      if (!route.params.action) return
      setAction(route.params.action)
      switch (route?.params?.action) {
        case PasswordAction.FORGET_PASSWORD:
          setTitle(t('login:label.enterVerificationCode'))
          break
        case PasswordAction.NEW_PASSWORD_REQUIRED:
          setSession(route.params.session ?? '')
          setHasBackBtn(false)
          break
        case PasswordAction.CHANGE_PASSWORD_IN_SESSION:
        default:
          break
      }
    }
  }, [route?.params, setSession, t])

  const handleBack = () => {
    if (action === PasswordAction.CHANGE_PASSWORD_IN_SESSION) {
      toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
    }
    navigation.goBack()
  }

  const handleChangePassword = async () => {
    if (!isPasswordMatch) return
    const userSession = userSessionService.getUserSession()
    if (action === PasswordAction.NEW_PASSWORD_REQUIRED) {
      const result = await Auth.respondNewPasswordChallenge(
        email,
        newPassword,
        session,
      )
      if (result?.errorCode) {
        if (result.errorCode === ErrorCode.TEMP_PASSWORD_EXPIRED_SESSION) {
          toggleAuthState(AUTH_STATE.RESET_PASSWORD, false)
          const newAuthState = toggleAuthState(AUTH_STATE.LOGIN, false)
          return navigation.navigate(newAuthState.toLowerCase())
        }
        return
      }
      const newAuthState = toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
      return navigation.navigate(newAuthState.toLowerCase())
    }
    if (action === PasswordAction.FORGET_PASSWORD) {
      const result = await Auth.confirmForgetPassword(email, code, newPassword)
      if (result?.errorCode) return
      toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
      return navigation.navigate(AUTH_STATE.LOGIN.toLowerCase())
    }
    if (action === PasswordAction.CHANGE_PASSWORD_IN_SESSION) {
      const result = await Auth.changePassword(
        oldPassword,
        newPassword,
        userSession?.userAttributes?.id || '',
      )
      if (result?.errorCode) return
      toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
      navigation.goBack()
    }
  }

  return (
    <Layout>
      <View style={styles.authContainer}>
        {exAndSmallScreen && action !== PasswordAction.NEW_PASSWORD_REQUIRED ? (
          <TouchableOpacity
            style={styles.backArrowBackground}
            onPress={() => handleBack()}
          >
            <SvgArrow
              orient="left"
              color={Colors.Contents.primary}
              width={15}
              height={15}
            />
          </TouchableOpacity>
        ) : null}
        <AuthHeader
          hasVRLogo={!exAndSmallScreen}
          style={{
            gap: 32,
          }}
          title={title}
        />
        {action === PasswordAction.FORGET_PASSWORD ? (
          <>
            <Label size="L">
              {t('login:label.sentCodeToEmail', {
                email,
              })}
            </Label>
            <View style={styles.codeInputStyle}>
              <TextInput textChange={setCode} label={'Code'} />
            </View>
          </>
        ) : null}
        {action === PasswordAction.CHANGE_PASSWORD_IN_SESSION ? (
          <View style={styles.codeInputStyle}>
            <PasswordTextInput
              onValidation={() => {}}
              onBlur={() => {}}
              textChange={setOldPassword}
              label={t('login:label.currentPassword')}
            />
          </View>
        ) : null}
        <PasswordInput
          onVerifyPassword={value => setIsPasswordMatch(value)}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
        />
        <View style={[styles.btnContainer]}>
          <Button
            style={styles.btnStyle}
            containerStyle={styles.btnContainerStyle}
            textStyle={styles.btnText}
            onPress={handleChangePassword}
            title={
              action === PasswordAction.FORGET_PASSWORD
                ? t('login:policy.password.continue')
                : t('login:label.changePassword')
            }
            a11yLabel={'ChangepasswordBtn'}
          />
          {hasBackBtn ? (
            <Button
              style={[styles.btnStyle, styles.subBtnStyle]}
              containerStyle={styles.btnContainerStyle}
              textStyle={[styles.btnText, styles.backBtnColor]}
              onPress={handleBack}
              title={t('login:pin.back')}
              a11yLabel={'ConfirmpasswordBtn'}
            />
          ) : null}
        </View>
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  authContainer: {
    gap: 8,
  },
  codeInputStyle: {
    marginTop: 16,
  },
  btnStyle: {
    width: '100%',
    marginTop: 12,
    backgroundColor: Colors.Backgrounds.accentPrimary,
  },
  subBtnStyle: {
    backgroundColor: Colors.Backgrounds.UI,
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  btnText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  backBtnColor: { color: Colors.Contents.primary },
  btnContainer: {
    borderRadius: 4,
    gap: 16,
  },
  btnContainerStyle: {
    width: '100%',
    paddingHorizontal: 0,
  },
  backArrowBackground: {
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
  },
})
