import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { Layout } from '../components/Layout'
import { Button } from 'components/common/Button'
import { SingleSelect } from 'src/design-system/components/Selects'
import { DividerWithText } from '../components/DividerWithText'
import { useNavigation } from '@react-navigation/native'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  useAuth,
  userPinSessionService,
  userSessionService,
} from 'src/context/auth'
import { AuthHeader } from '../components/Header'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { Colors, Typography } from 'src/design-system/theme'
import { PinInput } from '../components/PinInput'
import { sortList } from 'src/utils/sortList'
import { useTranslation } from 'react-i18next'
import { SubHeading } from 'src/design-system/components/Text'
import { ErrorCode } from 'src/context/auth/utils/type'

const PIN_LENGTH = 5

export const LoginWithPin: React.FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const exAndSmallScreen = isExSmallScreen || isSmallScreen
  const [pin, setPin] = useState<string>('')
  const { toggleAuthState } = useAuth()

  const usersOptions = useMemo(() => {
    const allUserPinSession = userPinSessionService.getAllUserPinSession()
    if (!allUserPinSession || allUserPinSession.length === 0) {
      return []
    }
    const allUserPinSessionArray = Object.values(allUserPinSession)
    const filteredPinsArray = allUserPinSessionArray.filter(pin => !!pin.pinId)
    const sortedUsers = sortList(filteredPinsArray)
    return sortedUsers.map(user => ({
      text: user.name,
      value: user.userId ?? '',
    }))
  }, [])

  const [selectedUser, setSelectedUser] = useState(usersOptions[0]?.value ?? '')

  const changeUser = (user: string) => {
    setSelectedUser(user)
  }

  const handleSignInWithEmail = async () => {
    if (userSessionService.hasV2Session()) {
      const result = await Auth.signOut()
      if (result?.errorCode) return
    }
    userSessionService.deleteUserSession()
    navigation.navigate(AUTH_STATE.LOGIN.toLowerCase())
  }

  const handleConfirmPin = useCallback(
    async (value?: string) => {
      const user = userPinSessionService.getUserPinSession(selectedUser)

      // TODO: Handle error
      if (!user) {
        return
      }

      const result = await Auth.signInWithPin(user, value ?? pin)

      if (result?.errorCode) {
        if (result.errorCode === ErrorCode.PIN_MAX_ATTEMPT) {
          navigation.navigate(AUTH_STATE.LOGIN.toLowerCase())
        }
        return
      }

      userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
      toggleAuthState(AUTH_STATE.UNLOCK_WITH_PIN, true)
    },
    [navigation, pin, selectedUser, toggleAuthState],
  )

  const handlePinInput = (value: string) => {
    setPin(value)
    if (value.length === PIN_LENGTH) {
      handleConfirmPin(value)
    }
  }

  return (
    <Layout>
      <View
        style={[
          styles.container,
          exAndSmallScreen ? styles.exSmallContainer : null,
        ]}
      >
        <AuthHeader
          hasVRLogo={!exAndSmallScreen}
          title={t('login:label.unlockWithPin')}
        />
        {selectedUser ? (
          <>
            <SingleSelect
              label={'Select user'}
              options={usersOptions}
              selected={selectedUser}
              onChange={(user: string) => changeUser(user)}
            />
            <PinInput onChange={handlePinInput} onConfirm={handleConfirmPin} />
            <DividerWithText text="Or" />
            <Button
              style={styles.btnStyle}
              containerStyle={styles.btnContainerStyle}
              onPress={handleSignInWithEmail}
              textStyle={styles.btnText}
              title={t('login:label.unlockWithEmail')}
            />
          </>
        ) : (
          <>
            <View style={styles.noPinContainer}>
              <SubHeading size="L" style={styles.noPinText}>
                {t('login:label.noPinWarning')}
              </SubHeading>
              <SubHeading size="L" style={styles.noPinText}>
                {t('login:label.noPinSuggestion')}
              </SubHeading>
            </View>
            <Button
              style={styles.noPinBtnStyle}
              containerStyle={styles.btnContainerStyle}
              onPress={handleSignInWithEmail}
              textStyle={styles.noPinBtnText}
              title={t('login:label.unlockWithEmail')}
            />
          </>
        )}
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 48,
  },
  exSmallContainer: {
    flex: 0.8,
    justifyContent: 'flex-start',
  },
  btnStyle: {
    width: '100%',
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
    backgroundColor: Colors.Backgrounds.UI,
  },
  btnText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
    color: Colors.Contents.primary,
  },
  noPinContainer: {
    marginTop: -40,
  },
  noPinText: {
    color: '#5D6066',
  },
  noPinBtnStyle: {
    width: '100%',
  },
  noPinBtnText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  btnContainerStyle: { width: '100%', paddingHorizontal: 0 },
})
