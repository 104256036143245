import { SyncStorage } from '../../../utils/SyncStorage'
import { Storage } from '../../../utils/Storage'

const ORGANISATION_ID = 'organisationId'
const ORG_SELECTED_STORAGE_NAME = 'OrganisationId'
const IS_ORG_SELECTING_NAME = 'is_selected'

class UserOrganisationService {
  private storage: Storage

  constructor() {
    this.storage = new Storage(ORG_SELECTED_STORAGE_NAME)
  }

  /* eslint-disable class-methods-use-this */
  // Manage Organisation ID
  public setOrganisationId(organisationId: string): void {
    SyncStorage.setItem(ORGANISATION_ID, organisationId)
  }

  public getOrganisationId(): string {
    return SyncStorage.getItem(ORGANISATION_ID) ?? ''
  }

  public removeOrganisationId(): void {
    SyncStorage.setItem(ORGANISATION_ID, '')
  }

  /* eslint-enable class-methods-use-this */
  // Manage Organisation Selection State
  public select(): void {
    this.storage.setItem(IS_ORG_SELECTING_NAME, true)
  }

  public deselect(): void {
    this.storage.setItem(IS_ORG_SELECTING_NAME, false)
  }

  public isSelected(): boolean {
    return this.storage.getItem(IS_ORG_SELECTING_NAME) ?? false
  }
}

export const userOrganisationService = new UserOrganisationService()
